<template>
  <div class="privacy">
    <div class="page-content">
      <BaseTextblock>
        <h1>Impressum</h1>

        <h3>Angaben gemäß § 5 TMG:</h3>
        <p>
          Schützengilde „Hubertus“ Pfraundorf e. V.<br>
          Schützenmeister Thomas Berger<br>
          Eschenweg 1a<br>
          83064 Raubling<br>
          Germany<br>
        </p>

        <h3>Kontakt:</h3>
        <p>
          <a href="mailto:vorstand@hubertus-pfraundorf.de">vorstand@hubertus-pfraundorf.de</a>
        </p>

        <h3>Haftung für Inhalte</h3>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§
          8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
          eine rechtswidrige Tätigkeit hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
          nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
          ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
          Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </p>

        <h3>Haftung für Links</h3>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
          keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
          Gewähr übernehmen.
          Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
          der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
          mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
          der Verlinkung nicht erkennbar.
        </p>
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
          Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
          Rechtsverletzungen
          werden wir derartige Links umgehend entfernen.
        </p>

        <h3>Urheberrecht</h3>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
          dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
          jede Art der
          Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
          Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
          für den privaten,
          nicht kommerziellen Gebrauch gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
          Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
          gekennzeichnet.
          Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
          bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
          derartige Inhalte umgehend entfernen.
        </p>
        <br>
        <p>
          Quelle: <a href="https://www.e-recht24.de/">e-recht24.de</a>
        </p>
      </BaseTextblock>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'ImprintView',
  components: {
  },
  head: {
    title: 'Impressum | SG Hubertus Pfraundorf e.V.',
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '~@/assets/scss/abstract/_variables.scss';

  .privacy {
    padding: 25px 0;

    @media all and (min-width: $tablet) {
      padding: $page-padding;
    }
  }

  .page-content {
    padding: 0 $padding-sides;
  }

  .base-textblock {
    * {
      text-align: left;
    }

    h3 {
      margin-top: 25px;

      @media all and (min-width: $tablet) {
        margin-top: 50px;
      }
    }
  }
</style>
